import moment from 'moment'
import mock from '../mock'

const utilities = {
  status: [
    {
      name: 'Pending',
      id: 1
    },
    {
      name: 'Inprogress',
      id: 2
    },
    {
      name: 'Completed',
      id: 3
    },
    {
      name: 'On Hold',
      id: 4
    },
    {
      name: 'Cancelled',
      id: 5
    },
    {
      name: 'Sent for Review',
      id: 6
    },
    {
      name: 'Request Changes',
      id: 7
    },
    {
      name: 'To Do',
      id: 8
    },
    {
      name: 'Overue',
      id: 9
    }
  ],
  dateOptions: [
    {
      id: [moment().startOf('D').valueOf(), moment().endOf('D').valueOf()],
      name: 'Today'
    },
    {
      id: [moment().startOf('week').startOf('D').valueOf(), moment().endOf('week').endOf('D').valueOf()],
      name: 'This Week'
    },
    {
      id: [moment().startOf('M').startOf('D').valueOf(), moment().endOf('M').endOf('D').valueOf()],
      name: 'This Month'
    },
    {
      id: [moment().subtract(1, 'M').startOf('M').startOf('D').valueOf(), moment().subtract(1, 'M').endOf('M').endOf('D').valueOf()],
      name: 'Last Month'
    },
    {
      id: [],
      name: 'All'
    }
  ],
  completeOption: [
    {
      id: 1,
      name: '0% - 25%'
    },
    {
      id: 2,
      name: '26% - 50%'
    },
    {
      id: 3,
      name: '51% - 75%'
    },
    {
      id: 4,
      name: '75% - 100%'
    }
  ]
}

mock.onPost('/api/tasks/status/dropdown').reply(200, utilities.status)
mock.onPost('/api/tasks/date/dropdown').reply(200, utilities.dateOptions)
mock.onPost('/api/tasks/completepercentage/dropdown').reply(200, utilities.completeOption)