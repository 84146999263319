// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import { orgUserId } from '@src/helper/sassHelper'
const userId = orgUserId()

export const getData = createAsyncThunk('appServices/getData', async params => {
  const response = await axios.post('/services/list', params)
  return {
    params,
    data: response.data.services.services,
    totalPages: response.data.services.total
  }
})

export const getGroupByData = createAsyncThunk('appServices/getGroupByData', async params => {
  const response = await axios.post('/services/listbygroup', params)
  return {
    params,
    data: response.data.services.services,
    totalPages: response.data.services.total
  }
})

export const exportService = createAsyncThunk('appServices/exportService', async params => {
  const response = await axios.post(`/services/exportToExcel`, params)

  const result = await response.data.services.map((obj) => {

    const data = {}
    data['name'] = obj.name
    data['category'] = obj.categoriesname
    data['professionalfee'] = obj.professionalfee ?? 0
    data['sop'] = obj.sopcount === '0' ? "No" : 'Yes'
    data['subtasks'] = obj.subtaskcount === '0' ? "No" : 'Yes'
    data['taxrate'] = obj.taxrate
    data['description'] = obj.description
    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Name', 'Category', 'Professional Fee', 'SOP', 'Sub Tasks', 'Tax Rate', 'Description']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'service.xlsx')

})

export const getService = createAsyncThunk('appServices/getService', async id => {
  const response = await axios.post('/services/get', { id })
  return response.data.services
})

export const addService = createAsyncThunk('appServices/addService', async (service, { rejectWithValue }) => {
  try {
    const response = await axios.post('/services/create', service)
    return response.data.services
  } catch (ex) {
    return rejectWithValue(ex.response)
  }
})

export const updateService = createAsyncThunk('appServices/updateService', async (service, { rejectWithValue }) => {

  try {
    const response = await axios.post(`/services/update`, service)
    return { service: response.data.services }
  } catch (ex) {
    return rejectWithValue(ex.response)
  }
})

export const deleteService = createAsyncThunk('appServices/deleteService', async (id, { dispatch, getState }) => {
  await axios.post('/services/delete', { id, updatedBy: userId })
  await dispatch(getData(getState().service.params))
  return id
})

export const updateBulk = createAsyncThunk('appServices/updateStatus', async (data, { dispatch, getState }) => {
  await axios.post(`/services/updateselectedrecords`, { ...data, updatedBy: userId })
  await dispatch(getData(getState().service.params))
  return ''
})

export const updateStatus = createAsyncThunk('appServices/updateStatus', async (data, { dispatch, getState }) => {
  await axios.post(`/services/statusupdate`, data)
  await dispatch(getData(getState().service.service))
  return ''
})

export const listWokflow = createAsyncThunk('appServices/listWokflow', async (id, { }) => {
  const response = await axios.post('/workflows/list', { serviceId: id })
  return response.data.workflows
})

export const addWokflow = createAsyncThunk('appServices/addWokflow', async (data, { dispatch }) => {
  await axios.post('/workflows/create', data).then(async () => {
    await dispatch(listWokflow(data.serviceId))
  })

  return ''
})

export const updateWorkflow = createAsyncThunk('appServices/updateWorkflow', async (data, { dispatch }) => {
  await axios.post('/workflows/update', data).then(async () => {
    await dispatch(listWokflow(data.serviceId))
  })
  return ''
})

export const deleteWokflow = createAsyncThunk('appServices/deleteWokflow', async (data, { dispatch }) => {
  const response = await axios.post('/workflows/delete', { id: data.workflowId, updatedBy: userId })
  const id = data.id
  await dispatch(listWokflow(id))
  return response.data.workflows
})

export const clientPortalClone = createAsyncThunk('appServices/clientPortalClone', async (obj, { }) => {
  await axios.post('/services/clone', obj)
  return ''
})

export const applyFilter = createAsyncThunk('appServices/applyFilter', async (val, { }) => {

  return val
})

export const setActiveFilter = createAsyncThunk('appServices/setActiveFilter', async (val, { }) => {

  return val
})

export const setHeaderList = createAsyncThunk('appServices/setHeaderList', async (val, { }) => {
  return val
})

export const setGroupByOptions = createAsyncThunk('appServices/setGroupByOptions', async (val, { }) => {
  return val
})

export const appServicesSlice = createSlice({
  name: 'appServices',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedService: null,
    workFlowLists: [],
    Errors: [],
    filter: null,
    activefilter: {},
    headerList: [],
    groupByOptions: {
      name: '',
      title: '',
      options: []
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getGroupByData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(addService.rejected, (state, action) => {
        state.Errors = action.payload.data.errors
      })
      .addCase(updateService.rejected, (state, action) => {
        state.Errors = action.payload.data.errors
      })
      .addCase(getService.fulfilled, (state, action) => {
        state.selectedService = action.payload
      })
      .addCase(listWokflow.fulfilled, (state, action) => {
        state.workFlowLists = action.payload
      })
      .addCase(applyFilter.fulfilled, (state, action) => {
        state.filter = action.payload
      })
      .addCase(setActiveFilter.fulfilled, (state, action) => {
        state.activefilter = action.payload
      })
      .addCase(setHeaderList.fulfilled, (state, action) => {
        state.headerList = action.payload
      })
      .addCase(setGroupByOptions.fulfilled, (state, action) => {
        state.groupByOptions = action.payload
      })
  }
})

export default appServicesSlice.reducer
