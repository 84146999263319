// ** React Imports
import { Fragment, useEffect, useState } from 'react'

import Avatar from '@components/avatar'

import UILoader from '@components/ui-loader'
import Spinner from '@components/spinner/Loading-spinner'
import {
  Send,
  Edit,
  Info,
  Save,
  PieChart,
  Download,
  TrendingUp,
  CheckCircle,
  ChevronDown,
  ArrowDownCircle
} from 'react-feather'

// ** Reactstrap Imports
import {
  Card,
  Col,
  CardTitle,
  CardHeader,
  UncontrolledTooltip,
  Table
} from 'reactstrap'

// ** Styles
import '@styles/react/apps/app-invoice.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'
import axios from '@src/configs/axios/axiosConfig'
import moment from 'moment'

// ** Vars
const statusObj = {
  Sent: { color: 'light-secondary', icon: <Send size={14} /> },
  Paid: { color: 'light-success', icon: <CheckCircle size={14} /> },
  Draft: { color: 'light-primary', icon: <Save size={14} /> },
  Downloaded: { color: 'light-info', icon: <ArrowDownCircle size={14} /> },
  'Past Due': { color: 'light-danger', icon: <Info size={14} /> },
  'Partial Payment': { color: 'light-warning', icon: <PieChart size={14} /> }
}

const BillingHistory = ({ refreshHistory }) => {

  const [data, setData] = useState([])
  const [block, setBlock] = useState(false)
  const Loader = () => {
    return (
      <Fragment>
        <Spinner />
      </Fragment>
    )
  }

  const downloadPdf = async (row) => {

    setBlock(true)
    axios.post('/billings/exportpdf', { id: row.id })
      .then((res) => {
        const downloadLink = document.createElement("a")
        const fileName = `${row.uniqueno}.pdf`
        downloadLink.href = res.data.pdf
        downloadLink.download = fileName
        downloadLink.click()
        
        setBlock(false)
      })
      .catch((err) => {
        setBlock(false)
        console.log(err)
      })
  }

  const getData = () => {

    axios.post('/billings/billinghistory')
      .then((res) => {
        setData(res.data.invoices)
      })
      .catch(() => {
      })
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (refreshHistory) {
      getData()
    }
  }, [refreshHistory])

  const Icon = (status) => {
    return statusObj[status] ? statusObj[status].icon : <CheckCircle size={14} />
  }

  return (
    < div className='invoice-list-wrapper' >
      <Card>
        <UILoader blocking={block} loader={<Loader />}>
          <CardHeader className='py-1'>
            <CardTitle tag='h4'>Billing History</CardTitle>
          </CardHeader>
          <div className='invoice-list-dataTable react-dataTable'>
            <Table responsive className='table table-hover'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    <TrendingUp size={14} />
                  </th>
                  <th>Total</th>
                  <th>Issued Date</th>
                  <th>Due Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, k) => {
                  return (<tr key={k}>
                    <td>
                      {row.uniqueno}
                    </td>
                    <td>
                      <Fragment>
                        <Avatar color={statusObj[row.status] ? statusObj[row.status].color : 'success'} icon={Icon(row.status)} id={`av-tooltip-${row.id}`} />
                        <UncontrolledTooltip placement='top' target={`av-tooltip-${row.id}`}>
                          <span className='fw-bold'>{row.status}</span>
                          <br />
                          <span className='fw-bold'>Balance:</span> {row.balance}
                          <br />
                          <span className='fw-bold'>Due Date:</span> {row.dueDate}
                        </UncontrolledTooltip>
                      </Fragment>
                    </td>
                    <td>
                      {row.currencysymbol} {row.totalamount || 0}
                    </td>
                    <td>
                      {moment(row.billingstart, 'x').format('MMM DD, Y')}
                    </td>
                    <td>
                      {moment(row.billingstart, 'x').add(5, 'd').format('MMM DD, Y')}
                    </td>
                    <td>
                      <Col className='cursor-pointer' onClick={() => { downloadPdf(row) }}>
                        <Download size={17} className='mx-1' />
                      </Col>
                    </td>
                  </tr>)
                })
                }
              </tbody>
            </Table>
          </div>
        </UILoader>
      </Card>
    </div >
  )
}

export default BillingHistory
