// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'

export const organizationUserDetails = createAsyncThunk('appDashboard/organizationUserDetails', async (data, { }) => {
    const response = await axios.post('dashboard/organizationUserDetail', data)
    return response.data.organizationUserDetail
})

export const employeeActivity = createAsyncThunk('appDashboard/employeeActivity', async (data, { }) => {
    const response = await axios.post('dashboard/employeeActivity', data)
    return response.data.employeeActivity
})

export const attendanceactivity = createAsyncThunk('appDashboard/attendanceactivity', async (data, { }) => {
    const response = await axios.post('auditlogs/attendanceactivity', data)
    return response.data.attendances
})


export const employeeType = createAsyncThunk('appDashboard/employeeType', async (data, { }) => {
    const response = await axios.post('dashboard/employeeType', data)
    return response.data.employeeType
})

export const averagePunchInPunchOut = createAsyncThunk('appDashboard/averagePunchInPunchOut', async (data, { }) => {
    const response = await axios.post('dashboard/averagePunchInPunchOut', data)
    return response.data.averagePunchInPunchOut
})

export const reimbursementDetails = createAsyncThunk('appDashboard/reimbursementDetails', async (data, { }) => {
    const response = await axios.post('dashboard/reimbursementDetails', data)
    return response.data.reimbersementDetail
})

export const reimbursementByEmployees = createAsyncThunk('appDashboard/reimbursementByEmployees', async (data, { }) => {
    const response = await axios.post('dashboard/reimbursementByEmployees', data)
    return response.data.reimbursementByEmployees
})

export const holidaysList = createAsyncThunk('appDashboard/holidaysList', async (data, { }) => {
    const response = await axios.post('dashboard/holidaysList', data)
    return response.data.holidaysList
})

export const leaveList = createAsyncThunk('appDashboard/leaveList', async (data, { }) => {
    const response = await axios.post('dashboard/leaveList', data)
    return response.data.leaveList
})

export const appDashboardSlice = createSlice({
    name: 'appInvoice',
    initialState: {
        organizationUserDetail: {},
        attendanceactivity: [],
        employeeTypeData: [],
        reimbursementDetails: [],
        reimbursementByEmployees: [],
        averagePunchInPunchOut: [],
        holidaysList: [],
        leaveList :[]
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(organizationUserDetails.fulfilled, (state, action) => {
            state.organizationUserDetail = action.payload
        })
        builder.addCase(attendanceactivity.fulfilled, (state, action) => {
            state.attendanceactivity = action.payload
        })
        builder.addCase(employeeType.fulfilled, (state, action) => {
            state.employeeTypeData = action.payload
        })
        builder.addCase(reimbursementDetails.fulfilled, (state, action) => {
            state.reimbursementDetails = action.payload
        })
        builder.addCase(reimbursementByEmployees.fulfilled, (state, action) => {
            state.reimbursementByEmployees = action.payload
        })
        builder.addCase(averagePunchInPunchOut.fulfilled, (state, action) => {
            state.averagePunchInPunchOut = action.payload
        })
        builder.addCase(holidaysList.fulfilled, (state, action) => {
            state.holidaysList = action.payload
        })
        builder.addCase(leaveList.fulfilled, (state, action) => {
            state.leaveList = action.payload
        })
    }
})

export default appDashboardSlice.reducer
