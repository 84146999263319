import mock from '../mock'

const utilities = {
  status: [
    {
      name: 'Active',
      id: 1,
      color: 'primary'
    },
    {
      name: 'In Active',
      id: 2,
      color: 'danger'
    }
  ],
  columnslist: [
    {
      name: 'Name',
      id: 'name'
    },
    {
      name: 'Categories',
      id: 'categoryid'
    },
    {
      name: 'Status',
      id: 'status'
    }
  ],
  general: [
    {
      name: 'Yes',
      id: 'Yes'
    },
    {
      name: 'No',
      id: 'No'
    }   
  ]

}

mock.onPost('/api/services/status/dropdown').reply(200, utilities.status)
mock.onPost('/api/services/general/dropdown').reply(200, utilities.general)
