// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const setIds = createAsyncThunk('appClient/setIds', async (val, { }) => {
  return val
})

export const appTablesSlice = createSlice({
  name: 'appTables',
  initialState: {
    ids : []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(setIds.fulfilled, (state, action) => {
        state.ids = action.payload
      })

  }
})

export default appTablesSlice.reducer
