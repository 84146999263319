import mock from '../mock'

const utilities = {
  status: [
    {
      name: 'Active',
      id: 1,
      color: 'primary'
    },
    {
      name: 'In Active',
      id: 2,
      color: 'danger'
    }
  ],
  clientAccessStatus: [
    {
      name: 'Enabled',
      id: true,
      color: 'primary'
    },
    {
      name: 'Not Enabled',
      id: false,
      color: 'danger'
    }
  ],
  leadsStatus: [
    {
      name: 'Active',
      id: 1,
      color: 'primary'
    },
    {
      name: 'inactive',
      id: 2,
      color: 'danger'
    },
    {
      name: 'Converted',
      id: 3
    }
  ],
  columnslist: [
    {
      name: 'Customer Name',
      id: 'name'
    },
    {
      name: 'Contact Person',
      id: 'contactpersonname'
    },
    {
      name: 'Mobile No',
      id: 'contactnumber'
    },
    {
      name : 'Business Entity',
      id : 'businessentities'
    },
    {
      name: 'Created On',
      id: 'createdon'
    },
    {
      name: 'Status',
      id: 'status'
    },
    {
      name: 'Client Portal',
      id: 'clientaccessflag'
    }
  ]

}

export  const documentPlaces = [
  { id: 1, name: 'In', color: 'priamry' },
  { id: 2, name: 'Out', color: 'danger' }
]


mock.onPost('/api/clients/status/dropdown').reply(200, utilities.status)

mock.onPost('/api/clients/documentstatus/dropdown').reply(200, documentPlaces)

mock.onPost('/api/clients/clientaccess/dropdown').reply(200, utilities.clientAccessStatus)

mock.onPost('/api/clients/leadsstatus/dropdown').reply(200, utilities.leadsStatus)

mock.onPost('/api/clients/columns/dropdown').reply(200, utilities.columnslist)