// ** Custom Components
import Avatar from '@components/avatar'
import { Fragment, useEffect, useState } from 'react'
import axios from '@src/configs/axios/axiosConfig'
const profileImg = require('@src/assets/images/avatars/avatar-blank.png').default
const ProfileImage = ({ fileId, imgWidth, imgHeight }) => {

  const [fileData, setFileData] = useState(null)
  const getFileData = () => {
    axios.post('/files/getFileData', { id: fileId }).then((res) => {
      setFileData(res.data.filedata)
    }).catch((err) => { console.log(err) })

  }

  useEffect(() => {
    if (fileId !== '' && fileId) {
      getFileData()
    }   

  }, [fileId])

  return (
    <Fragment>
      {!fileData ? <Avatar imgHeight={imgHeight} imgWidth={imgWidth} color='light-danger' img={profileImg} /> : <Avatar imgHeight={imgHeight} imgWidth={imgWidth} img={fileData} />}
    </Fragment>
  )
}
export default ProfileImage
