// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
//import axios from '@src/configs/axios/axiosConfig'

export const fileStoreInredux = createAsyncThunk('appDashboard/fileStoreInredux', async (data, { }) => {

  return data
})

export const appDashboardSlice = createSlice({
  name: 'appInvoice',
  initialState: {
    data: [],
    formData : null
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(fileStoreInredux.fulfilled, (state, action) => {
      state.formData = action.payload
    }) 
     
  }
})

export default appDashboardSlice.reducer
