import axios from '@src/configs/axios/axiosConfig'
import moment from 'moment'

export const indianFormattedPrice = (val) => {

  const value = String(val)

  let removedValue = ''
  let finalVal = ''
  if (value.includes('.')) {
    removedValue = value.slice(value.indexOf('.'))
    finalVal = value.replace(removedValue, '')
    finalVal = finalVal.toString()
  } else {
    finalVal = value.toString()
  }
  let lastThree = finalVal.substring(finalVal.length - 3)
  const otherNumbers = finalVal.substring(0, finalVal.length - 3)
  if (otherNumbers !== '') lastThree = `,${lastThree}`
  const res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree
  return res + (removedValue === '' ? '.00' : `.${removedValue.toString().split('.')[1]}`)
}

export const hoursFormat = (totalhours) => {

  const decimalTimeString = totalhours
  let decimalTime = parseFloat(decimalTimeString)
  decimalTime = decimalTime * 60 * 60
  let hours = Math.floor((decimalTime / (60 * 60)))
  decimalTime = decimalTime - (hours * 60 * 60)
  let minutes = Math.floor((decimalTime / 60))

  if (hours < 10) {
    hours = `0${hours}`
  }
  if (minutes < 10) {
    minutes = `0${minutes}`
  }

  return (`${isNaN(hours) ? '00' : hours}Hrs : ${isNaN(minutes) ? '00' : minutes}Mins`)

}

export const currencyConvert = async (base_currency, currencies) => {

  const res = await axios.get(
    `https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_C8lWFFJJ2Q2soCa9lcN99U1dc8yl7A4gUoXNyjhj&base_currency=${base_currency}&currencies=${currencies}`)


  return res?.data?.data[currencies]
}

export const adjustTime = (inputTime) => {

  const timeMoment = moment(inputTime, 'hh:mm A')
  const minutes = timeMoment.minutes()
  const nearestQuarter = Math.round(minutes / 15) * 15
  if (nearestQuarter === 60) {
    timeMoment.add(1, 'hour').minutes(0)
  } else {
    timeMoment.minutes(nearestQuarter)
  }

  return timeMoment
}

export const generateFromTimeSlots = () => {
  const timeSlots = []

  // Loop for 24-hour time slots (00:00 to 23:45)
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const hourStr24 = hour < 10 ? `0${hour}` : hour
      const minuteStr = minute < 10 ? `0${minute}` : minute
      const timeStr24 = `${hourStr24}:${minuteStr}` // 24-hour format ID

      // Convert 24-hour format to 12-hour format
      const isAM = hour < 12
      const hour12 = hour % 12 || 12  // Convert hour to 12-hour format (12 instead of 0 for midnight)
      const period = isAM ? 'AM' : 'PM'
      const timeStr12 = `${hour12 < 10 ? `0${hour12}` : hour12}:${minuteStr} ${period}` // 12-hour format name

      const timeId = timeStr24  // 24-hour format for id

      timeSlots.push({ name: timeStr12, id: timeId })
    }
  }

  return timeSlots
}

// Helper function to format the time difference into a readable format
const formatTimeDifference = (timeDifferenceInMinutes) => {
  if (timeDifferenceInMinutes < 60) {
    return `${timeDifferenceInMinutes} Mins`
  } else {
    const hours = Math.floor(timeDifferenceInMinutes / 60)
    const minutes = timeDifferenceInMinutes % 60
    return minutes === 0 ? `${hours} Hr` : `${hours} Hr ${minutes} Mins`
  }
}

export const generateToTimeSlots = (from) => {
  const timeSlots = []

  // Convert 'from' time to minutes for easier comparison
  const [fromHour, fromMinute] = from.split(':')
  const fromTimeInMinutes = (parseInt(fromHour) * 60) + parseInt(fromMinute)

  // Loop for 24-hour time slots (00:00 to 23:45)
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const hourStr24 = hour < 10 ? `0${hour}` : hour
      const minuteStr = minute < 10 ? `0${minute}` : minute
      const timeStr24 = `${hourStr24}:${minuteStr}` // 24-hour format ID

      // Convert 24-hour format to 12-hour format
      const isAM = hour < 12
      const hour12 = hour % 12 || 12  // Convert hour to 12-hour format (12 instead of 0 for midnight)
      const period = isAM ? 'AM' : 'PM'
      const timeStr12 = `${hour12 < 10 ? `0${hour12}` : hour12}:${minuteStr} ${period}` // 12-hour format name

      const timeInMinutes = (hour * 60) + minute

      // Only include times greater than 'from' time
      if (timeInMinutes > fromTimeInMinutes) {
        const timeDifference = timeInMinutes - fromTimeInMinutes
        const timeLabel = formatTimeDifference(timeDifference)
        timeSlots.push({ id: timeStr24, name: `${timeStr12} (${timeLabel})` })
      }
    }
  }

  return timeSlots
}
export const convertTo24HourFormat = (timeStr) => {
  // Parse the 12-hour time string
  const regex = /(\d{2}):(\d{2}):(AM|PM)/
  const match = timeStr.match(regex)

  if (match) {
    let hour = parseInt(match[1], 10)
    const minute = match[2]
    const period = match[3]

    if (period === 'AM' && hour === 12) {
      hour = 0
    } else if (period === 'PM' && hour !== 12) {
      hour += 12
    }


    return `${String(hour).padStart(2, '0')}:${minute}`
  } else {
    return "Invalid time format"
  }
}

export const getUrl = async (path) => {

  let url = ''
  const res = await axios.post('/files/getSignedUrl', { path })
  url = res?.data.url
  return url

}

