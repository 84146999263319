// ** React Imports
import { Link, useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { Can } from '@src/utility/context/Can.js'
// ** Custom Components
import Avatar from '@components/avatar'
import ProfileImage from '@src/views/components/avatar/ProfileImage.js'
// ** Utils
import axios from '@src/configs/axios/axiosConfig'
//import { isUserLoggedIn } from '@utils'
import { activeOrgDetails } from '@src/views/apps/organization/store/index.js'
// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'
import { handleLogout } from '@store/authentication'
import { AbilityContext } from '@src/utility/context/Can'

// ** Third Party Components
import { BarChart, Power, Settings, User, Users } from 'react-feather'
// ** Reactstrap Imports
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { activeOrganization, activeOrganizationid, orgUserId } from '@src/helper/sassHelper'

import { addNewUser, addGroup } from '@src/socketio.js'
import Cookies from 'js-cookie'

let activeOrg = activeOrganization()
let activeOrgId = activeOrganizationid()
const moduleList = activeOrg?.moduleslist || []
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const ListOrg = ({ organizations, setActiveOrg }) => {
    return (
        organizations.map((obj, k) => {
            return (<p key={k}
                className={`cursor-pointer px-1 pb-0 pt-0 ${obj.id === activeOrgId ? 'text-success' : ''}`}
                onClick={() => setActiveOrg(obj)}> {obj.name} </p>)
        })
    )
}

const UserDropdown = () => {
    // ** Store Vars
    const dispatch = useDispatch()

    // ** State
    const [userId, setUserId] = useState(orgUserId())    
    const [organizations, setOrganizations] = useState([])
    const [imageid, setImageId] = useState('')
    const ability = useContext(AbilityContext)
    const navigate = useNavigate()
    const store = useSelector(state => state.auth)

    const getUserGroup = async () => {
        await axios.post('/chatmessages/listgroup', { userId })
            .then((res) => {
                res?.data?.chatgroups?.map((ob) => {
                    addGroup(ob.id)
                })
            })

    }

    useEffect(() => {
        const userid = orgUserId()
        if (userid) {
            addNewUser(userid)
        }

        getUserGroup()
        setImageId(localStorage.getItem('userImage'))
    }, [])
    
    const getOrganizationUserImage = async (id) => {
        const user = { id }
        const res = await axios.post('organizationusers/get', user)
        const data = res.data.organizationuser
        if (res.data.organizationuser.fileid) {
            setImageId(res.data.organizationuser.fileid)
            localStorage.setItem('userImage', res.data.organizationuser.fileid)
        }
        const abilitytemp = []
        data.ability.forEach(obj => {
            const resource = obj.replace(obj.substring(obj.lastIndexOf("-")), '')
            if (moduleList.includes(resource) || moduleList.length === 0) {
                const action = obj.substring(obj.lastIndexOf("-") + 1)
                const opt = { action, subject: resource }
                abilitytemp.push(opt)
            }
        })
        localStorage.setItem('ability', JSON.stringify(abilitytemp))
        await ability.update(abilitytemp)        
    }

    const getOrganization = async (id, flg = true) => {

        const user = { userId: id }
        const res = await axios.post('organizationusers/usersorganization', user)
        if (res.data.organizations.length === 0) {
            navigate('/organization/create')
        }
        setOrganizations(res.data.organizations)

        const actorg = localStorage.getItem('activeOrganization') ? JSON.parse(localStorage.getItem('activeOrganization')) : null

        if (!actorg) {
            const activeOrg = res.data.organizations.find((obj) => obj.isdefault === true)
            const org = activeOrg || res.data.organizations[0]
            if (org) {
                await dispatch(activeOrgDetails(org))
                await getOrganizationUserImage(org?.organizationuserid)
            }
        } else {
            const activeorg = res.data.organizations.find((obj) => obj.id === actorg.id)
            if (activeorg !== undefined) {
                setUserId(activeorg?.organizationuserid)
                await dispatch(activeOrgDetails(activeorg))
                localStorage.setItem('activeOrganization', JSON.stringify(activeorg))
                if (flg) {
                    await getOrganizationUserImage(activeorg?.organizationuserid)
                }
            } else {
                await getOrganizationUserImage(activeOrg?.organizationuserid)
            }
        }
    }

    useEffect(async () => {
        activeOrg = activeOrganization()
        activeOrgId = activeOrganizationid()

        const userData = Cookies.get('userData') ? JSON.parse(Cookies.get('userData')) : null      

        if (userData) {

            if (userData?.id) {
                getOrganization(userData.id)
            }
        }
    }, [store.userData])

    const setActiveOrg = async (org) => {
        localStorage.setItem('activeOrganization', JSON.stringify(org))
        await dispatch(activeOrgDetails(org))
        await getOrganizationUserImage(org.organizationuserid)
        await navigate('/dashboard')
        await navigate(0)
    }

    const logoutFun = async () => {

        const task = localStorage.getItem('currentTask')
        const breakLog = localStorage.getItem('breakLogin')

        if (task || breakLog) {

            return MySwal.fire({
                title: 'Warning!',
                text: ' You have Running Task or Running Break,Please Stop timer to Logout!',
                icon: 'warning',
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })

        } else {
            await dispatch(handleLogout())
            navigate('/login')
        }
    }

    return (
        <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
            <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
                <div className='user-nav d-sm-flex d-none'>
                    <span className='user-name fw-bold'>{activeOrg?.organizationusersname ?? 'John Doe'}</span>
                    <span className='user-status'>{(activeOrg && activeOrg.rolename) || 'Admin'}</span>
                </div>

                <ProfileImage imgHeight={40} imgWidth={40} fileId={imageid}/>

            </DropdownToggle>
            <DropdownMenu end style={{ minWidth: '26rem' }}>
                <DropdownItem tag={Link} to={`/profile/view/${userId}?tabId=jobinfo`}>
                    <User size={14} className='me-75' />
                    <span className='align-middle'>Profile</span>
                </DropdownItem>

                <DropdownItem tag={Link} to='/reports'>
                    <BarChart size={14} className='me-75' />
                    <span className='align-middle'>Report</span>
                </DropdownItem>

                <Can I={'read'} a={'setting'}>
                    <DropdownItem tag={Link} to='/account-settings'>
                        <Settings size={14} className='me-75' />
                        <span className='align-middle'>Settings</span>
                    </DropdownItem>
                </Can>
                <DropdownItem tag={Link} to='/login' onClick={(e) => { e.preventDefault(); logoutFun() }}>
                    <Power size={14} className='me-75' />
                    <span className='align-middle'>Logout</span>
                </DropdownItem>

                <DropdownItem divider />
                <h5 className='px-1'>Organizations</h5>
                {organizations.length > 0 &&
                    <ListOrg organizations={organizations} setActiveOrg={setActiveOrg} />
                }
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export default UserDropdown
